import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const PositionManagement = () => {

    useEffect(() => {
        initStickyMenu();
    }, []);


    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation -Spread Position management"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation -Spread Position management"}/>
                <BreadCrumbs link={"trading"} title="Position management"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#1">Open & closed positions</a>
                                        <a href="#2">Exposure</a>
                                        <a href="#3">Autoclosing at expiry</a>
                                        <a href="#4">Liquidations</a>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    <a name={"fst"}>Spread Position management</a>
                                </h2>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        <StaticImage src={"../../gatsbyimg/docs/openpositions.png"} alt={"Open spread positions"} className={"border"}/>
                                    </div>
                                </div>
                                <a name={"1"}><h4 className={"pt-20"}>Open and closed positions</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        BitSpreader tracks the current status of your spread trading actions in form of <b>Spread Positions</b>. Spread position consists of two outright positions with
                                        equal size. Outright positions are the positions that you can view directly on your venue. The direction of the spread position is represented
                                        by the +/- sign in the SIZE column., LEG 2 was sold.
                                        <ul className="list-style1 pt-20">
                                            <li> [+] The position is open after buying the spread - LEG 1 was bought LEG 2 was sold</li>
                                            <li> [-] The position is open after selling the spread - LEG 1 was sold LEG 2 was bought</li>
                                        </ul>
                                        <br/>

                                        <b>Important notes about the positions</b>
                                        <ul className="list-style1 pt-20">
                                            <li>You can have one open position per spread market - if you have an open position on the market and you trade spread on this market, open position will be
                                                updated by the result of the consecutive trades. The outright contracts will be updated by the next trade size and the price will be calculated as the
                                                volume weighted price for each outright contract - in the same manner as it is done on the exchanges
                                            </li>
                                            <li>BitSpreader only creates spread positions for trades executed from within BitSpreader. Your direct activity on the venue is not synchronized currently
                                                into BitSpreader - in other words if you go directly to the exchange and trade there directly, BitSpreader will not update its positions. If you close
                                                one or both of the legs directly on the exchange, BitSpreader will not know it and will still display open position. In order to close such position -
                                                use <b>Mark Closed</b> button next to the open position
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                                <a name={"2"}><h4 className={"pt-20"}>Exposure</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        <StaticImage src={"../../gatsbyimg/docs/exposure.png"} alt={"Open spread positions"} className={"border"}/>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        In case one of the legs of the spread is not executed due to the lack of funds or some other limitations, the other leg that has been executed is not put into
                                        any open position, instead
                                        you can find it in the <b>Account Activity>Exposure tab</b>. One leg of your spread has landed without a pair and you can only close this leg. You can use <b>Market
                                        Close</b> button next to the leg -
                                        BitSpreader will send the order with the same size and opposite direction to the exchange and you will find a <b>Single Market Position</b> in the Closed
                                        Positions tab representing this activity along with its PnL calculation.

                                        <br/><br/>In case you would like to close the exposure directly on the exchange you can use <b>Mark Closed</b> button to remove the leg from the exposure tab

                                    </div>
                                </div>
                                <a name={"3"}><h4 className={"pt-20"}>Autoclosing at expiry</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        If you have an open position in which one of the contracts expires soon, BitSpreader will attempt auto-closing such position at midnight UTC one day before the
                                        date of expiry.
                                        You will get 3 messages with the notification about the need to close this position: 3, 2 and 1 day before the autoclosing is scheduled.
                                    </div>
                                </div>
                                <a name={"4"}><h4 className={"pt-20"}>Liquidations</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                       In case any of your outright positions have been liquidated due to low margin,  BitSpreader will not get the update from the exchanges about that event.
                                        That means that open positions in which legs have been liquidated, will be outdated. You need to close the other leg of such position and mark the position as closed in the BitSpreader using <b>Mark Closed</b> button
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default PositionManagement;
